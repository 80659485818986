@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  src: url('/fonts/Poppins/Poppins-LightItalic.ttf');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/Poppins/Poppins-Italic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/Poppins/Poppins-MediumItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/Poppins/Poppins-Bold.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: url('/fonts/Poppins/Poppins-BoldItalic.ttf');
}
